import { serializeTokens } from './tokens'
import { SerializedPoolConfig, PoolCategory } from './types'

const serializedTokens = serializeTokens()

const pools: SerializedPoolConfig[] = [
  {
    sousId: 0,
    stakingToken: serializedTokens.luchow,
    earningToken: serializedTokens.luchow,
    contractAddress: {
      4: '0xeEe5aDEb851a561EFA020E94281341B2a4684270',  // masterchef address for pid=0 pool
      1: '0xf4CbB1CCfCFE0DdD86077BAA66B6c1faf19eC393',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1000',
    sortOrder: 1,
    isFinished: false,
  },
]

export default pools

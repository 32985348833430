import { ChainId, Token } from '@pancakeswap/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { SerializedToken } from './types'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}

export const mainnetTokens = {
  luchow: new Token(
    MAINNET,
    '0xA5Ef74068d04ba0809B7379dD76Af5Ce34Ab7C57',
    18,
    'LUCHOW',
    'LuchowSwap Token',
    'https://lunachow.com/',
  ),
  busd: new Token(
    MAINNET,
    '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  usdt: new Token(
    MAINNET,
    '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    6,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
  weth: new Token(
    MAINNET,
    '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    18,
    'WETH',
    'Ethereum Token',
    'https://ethereum.org/en/',
  ),
  usdc: new Token(
    MAINNET,
    '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    6,
    'USDC',
    'Ethereum USD Coin',
    'https://www.centre.io/usdc',
  ),
  dai: new Token(
    TESTNET,
    '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    18,
    'DAI',
    'Ethereum DAI Coin',
    'https://www.centre.io/usdc',
  )
}

export const testnetTokens = {
  luchow: new Token(
    TESTNET,
    '0xde04E60e54D54B4a13DE8f6BeA35A41cBa518a1a',
    18,
    'LUCHOW',
    'LuchowSwap Token',
    'https://lunachow.com/',
  ),
  busd: new Token(
    TESTNET,
    '0x4e2442A6f7AeCE64Ca33d31756B5390860BF973E',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  usdt: new Token(
    TESTNET,
    '0x3B00Ef435fA4FcFF5C209a37d1f3dcff37c705aD',
    6,
    'USDT',
    'Tether USD',
    'https://tether.to/',
  ),
  weth: new Token(
    TESTNET,
    '0xc778417E063141139Fce010982780140Aa0cD5Ab',
    18,
    'WETH',
    'Ethereum Token',
    'https://ethereum.org/en/',
  ),
  usdc: new Token(
    TESTNET,
    '0xeb8f08a975Ab53E34D8a0330E0D34de942C95926',
    6,
    'USDC',
    'Ethereum USD Coin',
    'https://www.centre.io/usdc',
  ),
  dai: new Token(
    TESTNET,
    '0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735',
    18,
    'DAI',
    'Ethereum DAI Coin',
    'https://www.centre.io/usdc',
  )
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()

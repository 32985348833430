import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'

const serializedTokens = serializeTokens()

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
   */
  {
    pid: 0,
    lpSymbol: 'LUCHOW',
    lpAddresses: {
      4: '0xde04E60e54D54B4a13DE8f6BeA35A41cBa518a1a',
      1: '0xA5Ef74068d04ba0809B7379dD76Af5Ce34Ab7C57',
    },
    token: serializedTokens.luchow,
    quoteToken: serializedTokens.weth,
  },
  {
    pid: 1,
    lpSymbol: 'LUCHOW-WETH LP',
    lpAddresses: {
      4: '0xDb2A3C1DaAd807a832b37394B4f5d55f43819AD7',
      1: '0x1e3aeEDFEF45B96173D533a3F2844aAFB5836B5B',
    },
    token: serializedTokens.luchow,
    quoteToken: serializedTokens.weth,
  },
  {
    pid: 2,
    lpSymbol: 'LUCHOW-BUSD LP',
    lpAddresses: {
      4: '0x1edd6B0f76ba260cEE541ED45eC5Ace0f2396705',
      1: '0x77c50c958B50ee3401D75462Bab618903Cd23678',
    },
    token: serializedTokens.luchow,
    quoteToken: serializedTokens.busd,
  },
  {
    pid: 3,
    lpSymbol: 'LUCHOW-USDC LP',
    lpAddresses: {
      4: '0xA3eE1BD9Ca8E2dEc6fcf4B120d9A96684B681268',
      1: '0xde1F318Ec74AED11D48cd27a3A0F5ca2E7aFe497',
    },
    token: serializedTokens.luchow,
    quoteToken: serializedTokens.usdc,
  },
  {
    pid: 4,
    lpSymbol: 'WETH-BUSD LP',
    lpAddresses: {
      4: '0xab205A2456D6DB6F7B273fD8BC56aD23EB334A7F',
      1: '0x0237d02ED3C8d5b71AE8eCD2C137cf5746AA3760',
    },
    token: serializedTokens.busd,
    quoteToken: serializedTokens.weth,
  },
  {
    pid: 5,
    lpSymbol: 'WETH-USDT LP',
    lpAddresses: {
      4: '',
      1: '0x601C4680a397A5ECcb4Eb486B1e4DB0FeC96dAA1',
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.usdt,
  },
  {
    pid: 5,
    lpSymbol: 'WETH-USDT LP',
    lpAddresses: {
      4: '0x4CD323F0868C7e7c0d8589a4CD33e972ef8E1C70',
      1: '0x844d7DcB75b06e46aeBCA7d5F7D2B57f56a3b5E9',
    },
    token: serializedTokens.weth,
    quoteToken: serializedTokens.dai,
  },
]

export default farms
